.noviceContainer {
  max-width: 140rem;
  margin: 0 auto;
  margin-bottom: 8rem;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(40rem, 100%), 1fr));
  gap: 5rem;
}

.card {
  border: 1.7px solid #707070;
  padding: 1.3rem;
}
.imgWraper {
  width: 100%;
}
.imgWraper img {
  width: 100%;
}

.noviceTitle {
  color: #50c2dd;
  font-size: 3.1rem;
  padding: 1.5rem 0;
}

.novicetext {
  font-size: 1.8rem;
  line-height: 3rem;
  color: #606060;
  padding-bottom: 3rem;
}

.title {
  font-size: 12rem;
  color: #50c2dd;
  font-weight: 100;
  padding-bottom: 4rem;
}
.noviceContainer .card button {
  font-family: 'Barlow Condensed';
  font-weight: 200;
  float: left;
  margin-top: 0;
}

.noviceContainer button {
  float: right;
  margin-top: 5rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .noviceContainer {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 700px) {
  .title {
    font-size: 7.5rem;
    padding: 0 1.5rem 2rem 1.5rem;
  }

  .cardContainer {
    gap: 5rem;
    /* padding: 0 2rem; */
  }

  .noviceContainer {
    margin: 2rem 2rem;
  }

  .noviceContainer button {
    font-family: Barlow !important;
  }

  .noviceContainer .card button {
    font-family: 'Barlow Condensed';
    font-weight: 200;
    width: 100%;
    padding: 1rem 0;
    text-align: center;
  }

  .noviceContainer button {
    float: none;
    text-align: center;
    padding: 1rem 0;
    margin-top: 5rem;
    margin-right: 5rem;
    width: 100%;
  }

  .noviceContainer {
    padding: 0;
  }
}
