.header {
}

.navbar {
  background-color: #bebebe;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1%;
  z-index: 10;
}

.imgWrap {
  max-width: 16rem;
  margin: 0;
  padding: 0;
}

.navbar img {
  width: 100%;
}

.headerIcons {
  display: flex;
  font-size: 3rem;
  gap: 2rem;
  color: white;
  cursor: pointer;
}
.headerIcons > :hover {
  color: #50c2dd;
}

.headerMenu {
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  list-style: none;
  position: relative;
}

.headerMenu li {
  margin-right: 10rem;
  color: white;
  font-size: 2.8rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.headerMenu li:hover {
  color: #50c2dd;
}

.navbar button {
  background-color: white;
  font-size: 2rem;
  color: #50c2dd;
  padding: 1rem 3rem;
  border: none;
  border-radius: 1rem;
  margin-right: 2rem;
  cursor: pointer;
}

.navbar button:hover {
  background-color: #50c2dd;
  color: white;
}

.dropdownMenu {
  position: absolute;
  list-style: none;
  left: 36%;
  top: 100%;
  background-color: white;
  width: 38rem;
}

.dropdownMenu li {
  color: #50c2dd;
  border-bottom: 1px solid rgb(177, 169, 169);
  padding: 1rem 3rem;
  width: 100%;
}

a {
  text-decoration: none;
}

.dropdownMenu li:hover {
  color: white;
  background-color: #50c2dd;
}

.buttonContainer {
  display: flex;
}

/* ANIMATION */

.navMenu {
  position: relative;
  width: 53px;
  height: 50px;
  background-color: #50c2dd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 5%;
  padding: 12px 0;
  cursor: pointer;
  transition: all 0.5s;
  display: none;
}

.btn-link:active,
.btn-link:link {
  color: white;
}

.navTop {
  width: 70%;
  height: 3px;
  background-color: white;
  border-radius: 5px;
  /* margin-bottom: 5px; */
}
.navMiddle {
  width: 70%;
  height: 3px;
  background-color: white;
  border-radius: 5px;
  /* margin-bottom: 5px; */
}
.navBottom {
  width: 70%;
  height: 3px;
  background-color: white;
  border-radius: 5px;
}

/* ANIMATION */
.navMenu.active {
  transform: rotate(-180deg);
}
.navTop.active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.navMiddle.active {
  opacity: 0;
}
.navBottom.active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* ANIMATION */

@media screen and (max-width: 1425px) {
  .navbar {
    padding: 0.5rem 1rem;
  }

  .headerMenu li {
    margin-right: 5rem;
    color: white;
    font-size: 2.8rem;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }

  .navbar button {
    font-size: 90%;
  }
}
@media screen and (max-width: 1255px) {
  .navbar {
    padding: 0.5rem 1rem;
  }
  .imgWrap {
    max-width: 15rem;
  }

  .headerIcons {
    flex-direction: column;
    font-size: 2rem;
  }

  .headerMenu li {
    margin-right: 3rem;
    font-size: 2rem;
  }

  .navbar button {
    font-size: 70%;
  }
}

@media screen and (max-width: 850px) {
  .headerIcons {
    display: none;
  }
  .imgWrap {
    max-width: 12rem;
  }

  .headerMenu li {
    margin-right: 2.5rem;
    font-size: 1.7rem;
  }

  .imgWrap {
    max-width: 12rem;
  }

  .headerMenu li {
    margin-right: 1.5rem;
    font-size: 1.5rem !important;
  }

  .navbar button {
    font-size: 60%;
    padding: 1rem 0.7rem;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.1),
  only screen and (min-device-pixel-ratio: 1.1) {
  .navbar button {
    font-size: 70%;
  }

  .headerIcons {
    font-size: 2.5rem;
  }

  .imgWrap {
    max-width: 15rem;
  }

  .headerMenu li {
    margin-right: 6rem;
    font-size: 2.8rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25) {
  .navbar button {
    font-size: 60%;
  }

  .headerIcons {
    font-size: 2.5rem;
  }

  .imgWrap {
    max-width: 12rem;
  }

  .headerMenu li {
    margin-right: 5rem;
    font-size: 2.3rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .navbar button {
    font-size: 60%;
  }

  .headerIcons {
    font-size: 2.5rem;
  }

  .imgWrap {
    max-width: 12rem;
  }

  .headerMenu li {
    margin-right: 5rem;
    font-size: 2rem;
  }
  .dropdownMenu li {
    color: #50c2dd;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    padding: 0.7rem 2rem;
  }
  .imgWrap {
    max-width: 12rem;
    margin: 0;
    padding: 0;
  }

  .navbar img {
    width: 100%;
  }

  .headerIcons {
    display: none;
  }

  .headerMenu {
    display: flex;
  }

  .headerMenu > a {
    display: none;
  }

  .headerMenu > li {
    display: none;
  }

  .navbar button {
    padding: 0.5rem;
    font-size: 0.8rem;
    margin: 0.3rem;
  }
  .navMenu {
    display: flex;
  }

  .dropdownMenu {
    top: 3rem;
    left: -10rem;
  }

  .dropdownMenu li {
    color: #50c2dd;
    border-bottom: 1px solid rgb(177, 169, 169);
    padding: 1rem 3rem;
    width: 100%;
  }
}
