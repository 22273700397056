.formContainer {
  max-width: 140rem;
  margin: 0 auto;
  height: 80rem;
}
.form {
  max-width: 100rem;
  margin: 0 auto;
}

.formControl > input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 2rem;
  font-size: 4rem;
  font-family: 'Barlow Condensed';
  font-weight: 200;
}

.formControl > textarea {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 2rem;
  font-size: 4rem;
  font-family: inherit;
  height: 20rem;
  font-family: 'Barlow Condensed';
  font-weight: 200;
}

.form button {
  float: right;
  font-family: 'Barlow Condensed';
  font-weight: 200;
}

.formContainer h1 {
  font-size: 11rem;
  font-weight: 100;
  text-align: start;
  color: #50c2dd;
  padding: 2rem 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .formContainer {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 700px) {
  .formContainer {
    height: 50rem;
  }
  .form {
    padding: 0 2rem;
  }

  .formControl > input {
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
  }

  .formControl > textarea {
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    height: 12rem;
  }

  .form button {
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
  }

  .formContainer h1 {
    font-size: 5rem;
    line-height: 5rem;
    padding: 0 1.5rem 2rem 1.5rem;
  }

  .formContainer {
    padding: 0;
  }
}
