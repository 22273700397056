* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.25%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 200;
  overflow-x: hidden;
}

[id] {
  scroll-margin-top: 10rem;
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  [id] {
    scroll-margin-top: 15rem;
  }
} */

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('./assets/fonts/BarlowCondensed-ExtraLight.ttf');
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('./assets/fonts/BarlowCondensed-Light.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./assets/fonts/BarlowCondensed-Thin.ttf');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('./assets/fonts/Barlow-Light.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('./assets/fonts/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('./assets/fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./assets/fonts/Oswald-VariableFont_wght.ttf');
}
