footer {
  background-color: #bebebe;
  margin-top: -0.3rem;
}
.footerContainer {
  display: flex;
  justify-content: space-between;
  max-width: 140rem;
  margin: auto;
  padding: 2rem 0;
  border-bottom: 1px solid #707070;
}
.groupe {
  display: flex;
  align-items: center;
}

.groupeText {
  display: flex;
  flex-direction: column;
  font-size: 1.9rem;
  font-family: Oswald;
  color: #606060;
}

.groupeText span {
  font-size: 2.1rem;
  font-family: Roboto;
  color: white;
  font-weight: 300;
}

.icon {
  font-size: 6rem;
  color: white;
  padding: 3rem;
}
.footerText {
  color: white;
  font-size: 2.5rem;
}
.footerTexts {
  color: white;
  font-size: 2.5rem;
}

.footerContainerBottom {
  display: flex;
  justify-content: space-between;
  max-width: 140rem;
  margin: auto;
  padding: 6rem 0;
}

.footerIcons {
  text-align: center;
  padding: 1rem;
}
a {
  color: inherit;
}
.footerIcon {
  padding: 0 1rem;
  font-size: 4rem;
}

.textContainer {
  margin-bottom: 2rem;
}

.footerIcons > :hover {
  color: #50c2dd;
}

@media screen and (max-width: 1024px) {
  .footerContainer {
    display: flex;
    align-items: center;
    padding: 2rem 2rem;
    border-bottom: 1px solid #707070;
  }
  .groupe {
    padding-right: 5%;
    flex-direction: column;
    text-align: center;
  }

  .icon {
    font-size: 5rem;
    color: white;
    padding: 1rem;
  }

  .footerText {
    color: white;
    font-size: 2.5rem;
  }
  .footerTexts {
    color: white;
    font-size: 2.5rem;
  }

  .footerContainerBottom {
    padding: 3rem 2rem;
    font-size: 2rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .footerContainer {
    padding: 2rem 4rem;
    max-width: 110rem;
  }

  .imageContainerFooter {
    width: 19%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageContainerFooter img {
    width: 100%;
  }

  .footerContainerBottom {
    padding: 4rem 2rem;
    max-width: 110rem;
  }

  .groupeText {
    font-size: 1.5rem;
  }

  .groupeText span {
    font-size: 1.5rem;
  }

  .icon {
    font-size: 4rem;
    color: white;
    padding: 3rem;
  }

  .footerIcons {
    text-align: center;
    padding: 0.7rem;
  }

  .footerIcon {
    font-size: 3rem;
  }

  .footerText {
    font-size: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    border-bottom: 1px solid #707070;
  }
  .groupe {
    padding-right: 5%;
    display: flex;
    flex-direction: row;
  }

  .groupeText {
    font-size: 2rem;
  }

  .groupeText span {
    font-size: 1.8rem;
  }
  .icon {
    font-size: 5rem;
    color: white;
    padding: 3rem;
  }

  .footerText {
    color: white;
    font-size: 2.5rem;
  }

  .footerTexts {
    color: white;
    font-size: 2.5rem;
    margin: auto;
    margin-top: 2.5rem;
  }

  .footerContainerBottom {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 3rem 5rem;
    font-size: 2rem;
  }

  .footerContainer {
    padding: 2rem;
  }

  .imageContainerFooter {
    width: 60%;
  }

  .imageContainerFooter img {
    width: 100%;
  }

  .footerContainerBottom {
    padding: 2rem;
  }
}
