.titleDiv {
  max-width: 140rem;
  margin: auto;
}

.title {
  font-size: 11rem;
  font-weight: 100;
  color: #50c2dd;
  margin-bottom: 3rem;
}

.mapBackground {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 6rem;
    margin-bottom: 2rem;
    padding: 0 1.5rem;
  }
}
