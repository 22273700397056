.btn {
  font-size: 3rem;
  color: white;
  background-color: #50c2dd;
  font-family: 'Barlow Condensed';
  font-weight: 200;
  padding: 1rem 8rem;
  border: none;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  text-align: right;
  cursor: pointer;
}

.btn:hover {
  background-color: #37afca;
}

.btn:active {
  transform: translateY(-5px);
}
