.ponudbaContainer {
  max-width: 140rem;
  margin: auto;
  min-height: 95rem;
  margin-top: 9rem;
}

.imagesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(40rem, 100%), 1fr));
  gap: 4rem;
  overflow: hidden;
}
.imageWraper {
  width: 100%;
}
.imagesContainer img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  cursor: pointer;
  filter: brightness(70%);
  transition: all 0.3s ease-in-out;
  border: 3px solid #50c2dd;
  border-radius: 5px;
}
.ponudaTitles {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin-top: 1.6rem;
}
.ponudaTitles p {
  font-size: 2.5rem;
  font-family: Barlow;
  font-weight: 100;
  letter-spacing: 0.5px;
  width: auto;
  color: #bebebe;
  font-weight: 400;
}
.ponudaTitles div {
  background-color: #bebebe;
}
.title {
  font-size: 12rem;
  color: #50c2dd;
  font-weight: 100;
  margin: 4rem 0;
}

.div1 {
  width: 20rem;
}
.div2 {
  width: 22rem;
}
.div3 {
  width: 22rem;
}
.div4 {
  width: 15rem;
}
.div5 {
  width: 4rem;
}
.div6 {
  width: 34rem;
}

.imagesContainer img:hover {
  filter: brightness(100%);
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 7rem;
    color: #50c2dd;
    font-weight: 100;
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding: 0 1.5rem;
  }

  .imagesContainer {
    gap: 4rem;
    overflow: hidden;
    padding: 0 2rem;
  }

  .ponudaTitles p {
    font-size: 1.9rem;
    font-family: Barlow;
    font-weight: 100;
    letter-spacing: 0.5px;
    width: auto;
    color: #bebebe;
    font-weight: 400;
  }

  .div1 {
    width: 16rem;
  }
  .div2 {
    width: 17rem;
  }
  .div3 {
    width: 17rem;
  }
  .div4 {
    width: 4rem;
  }
  .div5 {
    width: 13rem;
  }
  .div6 {
    width: 25rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .ponudbaContainer {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 700px) {
  .title {
    font-size: 7rem;
    color: #50c2dd;
    font-weight: 100;
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding: 0 1.5rem;
  }

  .imagesContainer {
    gap: 4rem;
    overflow: hidden;
    padding: 0 2rem;
    margin-bottom: 5rem;
  }

  .ponudaTitles p {
    font-size: 1.9rem;
    font-family: Barlow;
    font-weight: 100;
    letter-spacing: 0.5px;
    width: auto;
    color: #bebebe;
    font-weight: 400;
  }

  .div1 {
    width: 16rem;
  }
  .div2 {
    width: 17rem;
  }
  .div3 {
    width: 17rem;
  }
  .div4 {
    width: 4rem;
  }
  .div5 {
    width: 13rem;
  }
  .div6 {
    width: 25rem;
  }

  .ponudbaContainer {
    padding: 0;
  }
}
