.main {
  width: 100%;
  height: 100vh;
  position: relative;
}
.main h1 {
  position: absolute;
  bottom: 10%;
  right: 5%;
  color: white;
  font-family: 'Barlow Condensed';
  /* font-family: Oswald; */

  font-size: 7.5rem;
  font-weight: 100;
  text-shadow: 1px 3px 3px rgb(8, 8, 8);
  text-transform: uppercase;
}

video {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  z-index: -1;
}

.main img {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: none;
}

.videoWrapper {
  height: 100vh;
}

/* .phonevideo {
  display: none;
}
.phonevideo video {
  display: none;
} */
@media screen and (max-width: 1000px) {
  .main h1 {
    font-size: 5rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .main h1 {
    font-size: 4.7rem;
  }
}

@media screen and (max-width: 700px) {
  .main h1 {
    font-size: 3.5rem;
    font-family: Barlow !important;
    padding: 0 2rem;
  }

  video {
    display: block;
    height: 100%;
  }
  /* .main img {
    display: block;
  }

  .phonevideo {
    display: block;
  } */
}
