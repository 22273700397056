.oNas {
  max-width: 1400px;
  margin: auto;
  min-height: 86rem;
  margin-top: 8rem;
}
.oNasTittle {
  font-size: 12.5rem;
  color: #50c2dd;
  font-weight: 100;
}

.textContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
  font-size: 2.1rem;
  line-height: 3rem;
  font-family: Roboto;
  font-weight: 300;
}

.img {
  display: block;
}

.koraciContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
  max-width: 100%;
}
.korak {
  margin-top: 5rem;
}

.korak p {
  font-size: 1.8rem;
  line-height: 3rem;
  font-family: Roboto;
  padding-right: 2rem;
  font-weight: 300;
}

.korak .title {
  font-size: 3.2rem;
  font-family: Barlow;
  color: #50c2dd;
  margin: 2rem 0;
}
.imgWrap {
  width: 100%;
  height: 25rem;
}

.korak img {
  width: 100%;
  height: 100%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .oNas {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 700px) {
  .oNasTittle {
    font-size: 8.5rem;
    color: #50c2dd;
    font-weight: 100;
    padding: 0 1.5rem;
  }

  .textContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    font-size: 1.8rem;
    line-height: 2.8rem;
    font-family: Roboto;
    font-weight: 300;
    padding: 0 2rem;
  }

  .textContainer p {
    font-family: Roboto !important;
  }

  .korak {
    padding: 0 2rem;
  }

  .korak .title {
    font-family: Barlow !important;
  }

  .korak p {
    font-family: Roboto !important;
  }
  .oNas {
    padding: 0;
  }
}
